import {
  InputGroup,
  FormControl,
  Button,
  Row,
  Container,
  Form,
} from "react-bootstrap";
import { useTranslation } from "react-i18next";

// import React, { useState } from "react";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

function AssetList(props) {
  const [etfList, setEtfList] = React.useState({});
  const [stockList, setStockList] = React.useState({});
  const [name, setName] = React.useState("100");
  const { t } = useTranslation();

  function handleChange(event) {
    setName(event.target.value);
  }

  function isEmpty(str) {
    return !str || str.length === 0;
  }

  useEffect(() => {
    props.onChange({
      target: {
        name: "assetList",
        value: {
          stockList: stockList,
          etfList: etfList,
          etfCount: getObjectValueSum(etfList),
          stockCount: getObjectValueSum(stockList),
        },
      },
    });
  }, [stockList, etfList]);

  function handleBadge(event, type = "etf") {
    const id = event.target.id;
    handleRemoveAsset(id, type);
  }

  function handleRemoveAsset(id, type = "etf") {
    const setFunction = type === "etf" ? setEtfList : setStockList;
    setFunction((prevState) => {
      let newState = { ...prevState };
      if (id in prevState) {
        if (prevState[id] === 1) {
          delete newState[id];
        } else {
          newState[id] = Math.max(0, prevState[id] - 1);
        }
      }
      return newState;
    });
  }

  function handleAddAsset(type = "etf") {
    const setFunction = type === "etf" ? setEtfList : setStockList;
    if (!isEmpty(name)) {
      const nameAsInt = parseInt(name);
      if (!isNaN(nameAsInt)) {
        const convertedName = nameAsInt.toString();
        setFunction((prevState) => {
          let etfListState = {
            ...prevState,
            [convertedName]: Object.prototype.hasOwnProperty.call(
              prevState,
              convertedName
            )
              ? prevState[convertedName] + 1
              : 1,
          };
          return etfListState;
        });
      }
    }
  }

  function getObjectValueSum(countObject) {
    return Object.values(countObject).reduce((a, b) => a + b, 0);
  }

  return (
    <Container>
      <Row>
        <Form.Label>{t("Savings rate")}</Form.Label>
        <InputGroup>
          <FormControl
            placeholder="100"
            aria-label="Euro amount"
            onChange={handleChange}
            value={name}
          />
          <InputGroup.Text>€</InputGroup.Text>
          <Button variant="outline-secondary" onClick={() => handleAddAsset()}>
            {t("Add ETF")}
          </Button>
          <Button
            variant="outline-secondary"
            onClick={() => handleAddAsset("stocks")}
          >
            {t("Add Stock")}
          </Button>
        </InputGroup>
      </Row>
      <Row>
        <Form.Label>{t("Savings Plans")}</Form.Label>
        <InputGroup>
          <InputGroup.Text className="mt-1 mb-1">
            ETF ({getObjectValueSum(etfList)})
          </InputGroup.Text>
          {Object.keys(etfList).map((key) => (
            <Button
              key={key}
              id={key}
              variant="secondary"
              onClick={(e) => handleBadge(e)}
              className="m-1"
            >
              {etfList[key]} x {key} € [-]
            </Button>
          ))}
          <InputGroup.Text className="mt-1 mb-1">
            {t("Stocks")} ({getObjectValueSum(stockList)})
          </InputGroup.Text>
          {Object.keys(stockList).map((key) => (
            <Button
              key={key}
              id={key}
              variant="success"
              onClick={(e) => handleBadge(e, "stocks")}
              className="m-1"
            >
              {stockList[key]} x {key} € [-]
            </Button>
          ))}
        </InputGroup>
      </Row>
    </Container>
  );
}

AssetList.propTypes = {
  onChange: PropTypes.func,
  t: PropTypes.func,
  // onCalculatorChange: PropTypes.func,
};

export default AssetList;
