import "./App.css";
import ComparisonView from "./components/ComparisonView";
import React from "react";
import { Navbar, Nav } from "react-bootstrap";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import { Route, Switch } from "react-router-dom";
import preval from "preval.macro";

const allProducts = [
  {
    id: "1",
    name: "Scalable",
    link: "https://de.scalable.capital/einladung/b8xqrd",
    orderTypes: ["market", "limit", "stop", "stop-limit"],
    plans: [
      {
        name: "Free broker",
        depot: {
          fee: 0,
          feePeriod: "month",
          minBillingPeriod: "monthly",
        },
        defaultPlan: {
          etf: {
            fee: 0.99,
            issuePremium: 0,
            percentage: 0,
            count: 1300,
            minSavingsRate: 25,
            maxSavingsRate: 5000,
          },
        },
        savingPlan: {
          etf: {
            _comment: "One free, prime dws, invesco, ishares free",
            fee: 0.99,
            percentage: 0,
            count: 1300,
            minSavingsRate: 25,
            maxSavingsRate: 5000,
          },
          stocks: {
            fee: 0.99,
            count: 1000,
            countSources: [
              "https://de.scalable.capital/presse/scalable-capital-erweitert-angebot-um-aktiensparplaene",
            ],
            percentage: 0,
            minSavingsRate: 25,
            maxSavingsRate: 5000,
          },
          general: {
            executionDates: ["1. (Lastschrift)"],
            exchange: ["Gettex"],
            feefree: 1,
            executionInterval: ["M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
        exchanges: {
          Gettex: {
            fees: [
              {
                volume: 250,
                operator: ">",
                fee: 0.99,
              },
            ],
            open: {
              open: "08:00",
              close: "22:00",
            },
          },
        },
      },
      {
        name: "Prime broker",
        depot: {
          fee: 2.99,
          feePeriod: "month",
          minBillingPeriod: "yearly",
        },
        savingPlan: {
          etf: {
            fee: 0,
            percentage: 0,
            count: 1300,
            minSavingsRate: 25,
            maxSavingsRate: 5000,
          },
          stocks: {
            fee: 0,
            count: 1000,
            percentage: 0,
            minSavingsRate: 250,
            maxSavingsRate: 5000,
          },
          general: {
            executionDates: ["1. (Lastschrift)"],
            exchange: ["Gettex"],
            executionInterval: ["M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
      },
      {
        name: "Prime broker flex",
        depot: {
          fee: 4.99,
          feePeriod: "month",
          minBillingPeriod: "monthly",
        },
        savingPlan: {
          etf: {
            fee: 0,
            percentage: 0,
            count: 1300,
            minSavingsRate: 25,
            maxSavingsRate: 5000,
          },
          stocks: {
            fee: 0,
            count: 1000,
            percentage: 0,
            minSavingsRate: 250,
            maxSavingsRate: 5000,
          },
          general: {
            executionDates: ["1. (Lastschrift)"],
            exchange: ["Gettex"],
            executionInterval: ["M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
      },
    ],
  },
  {
    id: "2",
    name: "Trade Republic",
    link: "https://traderepublic.com/",
    orderTypes: ["market", "limit", "stop"],
    plans: [
      {
        name: "Standard",
        depot: {
          fee: 0,
          feePeriod: "month",
          minBillingPeriod: "monthly",
        },
        savingPlan: {
          etf: {
            fee: 0,
            percentage: 0,
            count: 1500,
            countSources: [
              "https://support.traderepublic.com/de-de/90-Wie-kann-ich-einen-Sparplan-erstellen",
              "https://assets.traderepublic.com/assets/files/Sparplan-Universum.pdf",
            ],
            _comment:
              "Spreads sind an den Referenzmarkt XETRA, soweit dort handelbar, gebunden.",
            minSavingsRate: 10,
            maxSavingsRate: 5000,
          },
          stocks: {
            fee: 0,
            count: 2500,
            countSources: [
              "https://support.traderepublic.com/de-de/90-Wie-kann-ich-einen-Sparplan-erstellen",
              "https://assets.traderepublic.com/assets/files/Aktien-und-ETFs-Sparplan-Universum.pdf",
            ],
            percentage: 0,
            minSavingsRate: 10,
            maxSavingsRate: 5000,
          },
          general: {
            executionDates: ["1.", "15."],
            exchange: ["LSX"],
            executionInterval: ["2W", "M", "3M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
      },
    ],
  },
  {
    id: "3",
    name: "Smartbroker",
    link: "https://smartbroker.de/",
    orderTypes: ["market", "limit", "stop", "stop-limit"],
    plans: [
      {
        name: "Standard",
        depot: {
          fee: 0,
          fee_comment:
            "Verwahrentgelt beträgt derzeit 0,5% p.a. ab einer Cash-Quote in Höhe von 15%",
          feePeriod: "month",
          minBillingPeriod: "monthly",
        },
        savingPlan: {
          etf: {
            feeSources: [
              "https://assets.smartbroker.de/pdf/2021_06_14_Preis-Leistungsverzeichnis-Smartbroker.pdf",
            ],
            fee: 0.8,
            percentage: 0.2,
            countFree: 280,
            count: 600,
            countSources: ["https://smartbroker.de/sparplan/"],
            minSavingsRate: 25,
            maxSavingsRate: 3000,
            executionTime: "09:15 CET",
          },
          stocks: {
            fee: 0.8,
            percentage: 0.2,
            minSavingsRate: 125,
            maxSavingsRate: 3000,
            count: 102,
            types: ["dax", "mdax", "topus"],
            bruchstuecke: false,
          },
          general: {
            exchange: ["LSX"],
            executionDates: ["1."],
            executionInterval: ["2W", "M", "3M", "6M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
      },
    ],
  },
  {
    id: "4",
    name: "DKB",
    link: "https://dkb.de/",
    orderTypes: ["market", "limit", "stop", "stop-limit"],
    plans: [
      {
        name: "Standard",
        depot: {
          fee: 0,
          feePeriod: "month",
          minBillingPeriod: "monthly",
        },
        savingPlan: {
          etf: {
            fee: 1.5,
            percentage: 0,
            count: 871,
            minSavingsRate: 50,
            maxSavingsRate: 10000,
          },
          stocks: {
            fee: 1.5,
            percentage: 0,
            count: 30,
            minSavingsRate: 50,
            maxSavingsRate: 10000,
            bruchstuecke: false,
          },
          general: {
            exchange: ["Tradegate"],
            executionDates: ["5.", "20."],
            executionInterval: ["M", "2M", "3M", "6M", "12M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
      },
    ],
  },
  {
    id: "5",
    name: "Comdirect",
    link: "https://comdirect.de/",
    orderTypes: ["market", "limit", "stop", "stop-limit"],
    plans: [
      {
        name: "Standard",
        depot: {
          fee: 0,
          feePeriod: "month",
          minBillingPeriod: "monthly",
        },
        savingPlan: {
          etf: {
            fee: 0,
            count: 600,
            countSources: [
              "https://www.comdirect.de/geldanlage/wertpapier-sparplan.html#Vorteile",
              "https://www.comdirect.de/pbl/member/formcenter/DownloadPdfDocumentRH.do?redirected=947918&ePdfInstance=129074&ePageId=cori7105&pdf=&name=&combined=true",
            ],
            percentage: 1.5,
            minSavingsRate: 25,
            maxSavingsRate: 10000,
          },
          stocks: {
            fee: 0,
            percentage: 1.5,
            count: 300,
            countSources: [
              "https://www.comdirect.de/geldanlage/wertpapier-sparplan.html#Vorteile",
            ],
            minSavingsRate: 25,
            maxSavingsRate: 10000,
            bruchstuecke: false,
          },
          general: {
            exchange: ["Tradegate"],
            exchange_source: [
              "https://www.comdirect.de/faq/wertpapiersparplan-ausfuehrungsinformationen",
            ],
            execution_time: "15:36 Uhr",
            executionDates: ["1.", "7.", "15.", "23."],
            executionInterval: ["M", "2M", "3M"],
          },
        },
        lastUpdate: "2021-08-15T13:51:27.109Z",
      },
    ],
  },
];

class App extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <>
        <Navbar collapseOnSelect expand="lg" bg="white" variant="light">
          <div className="container d-flex justify-content-between">
            <Navbar.Brand href="#">pro VS con</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mr-auto">
                <Nav.Link href="neobroker-saving-plans">
                  Neobroker - {t("Savings Plans")}
                </Nav.Link>
              </Nav>
              {/* <NavDropdown
                    className="mr-auto"
                    title="Neobroker"
                    id="basic-nav-dropdown"
                  >
                    <NavDropdown.Item href="neobroker-saving-plans">
                      Saving plans
                    </NavDropdown.Item>
                    <NavDropdown.Item href="neobroker-trading">
                      ETF/Stocks Trading
                    </NavDropdown.Item>
                  </NavDropdown> */}
            </Navbar.Collapse>
          </div>
        </Navbar>
        <Switch>
          <Route path="/neobroker-saving-plans">
            <ComparisonView products={allProducts} />
          </Route>
          <Route path="/neobroker-trading">
            <div className="text-center">Not available, yet.</div>
          </Route>
          <Route exact path="/">
            <ComparisonView products={allProducts} />
          </Route>
          {/* <Route path="/about" component={About} />
          <Route component={NoMatch} /> */}
        </Switch>

        <div className="m-3 d-flex justify-content-center">
          <footer>
            <div className="text-center">
              {t("Last update")}:{" "}
              {preval`module.exports = new Date().toISOString();`}
            </div>
            <div className="text-center">proVScon - v0.0.1</div>
          </footer>
        </div>
      </>
    );
  }
}

App.propTypes = {
  t: PropTypes.func,
};

export default withTranslation("translations")(App);
