import React from "react";
// import './styles.css'
import ToggleButton from "react-bootstrap/ToggleButton";
import ToggleButtonGroup from "react-bootstrap/ToggleButtonGroup";
import ComparisonTable from "../ComparisonTable";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import AssetList from "../AssetList";
import { Row, Container, Form } from "react-bootstrap";
class ComparisonView extends React.Component {
  constructor(props) {
    super(props);
    this.compTable = React.createRef();
    this.state = {
      // store selected products in array
      calculatorState: {
        assetList: {
          stockList: {},
          etfList: {},
          etfCount: 0,
          stockCount: 0,
        },
      },
      selectedProducts: ["1", "2", "3"],
      // selectedViews: ["General", "ETF"],
    };
    this.handleCalculatorChange = this.handleCalculatorChange.bind(this);
    this.updateSelectedProducts = this.updateSelectedProducts.bind(this);
  }

  updateSelectedProducts(newSelectedProducts) {
    this.setState({
      selectedProducts: newSelectedProducts,
    });
    if (this.compTable.current != null) {
      this.compTable.current.updateProducts(newSelectedProducts);
    }
  }

  handleCalculatorChange(event) {
    const { name, value } = event.target;
    this.setState((prevState) => {
      let calculatorState = { ...prevState.calculatorState };
      calculatorState[name] = value;
      return { calculatorState };
    });
  }

  render() {
    const { t } = this.props;
    let groupedComparisonTables;
    if (this.state.selectedProducts.length > 0) {
      groupedComparisonTables = (
        <div>
          {/* <h3>Saving plan comparison</h3> */}
          <ComparisonTable
            ref={this.compTable}
            products={this.props.products.filter((product) =>
              this.state.selectedProducts.includes(product.id)
            )}
            calculatorInput={this.state.calculatorState}
          />
          <div className="text-center">{t("productDisclaimer")}</div>
        </div>
      );
    } else {
      groupedComparisonTables = (
        <Container>
          <Row className="m-3 text-center">
            <p>{t("Select a product.")}</p>
          </Row>
        </Container>
      );
    }

    return (
      <div className="container">
        <h1 className="text-left my-1">Neobroker - {t("Savings Plans")}</h1>
        <Container fluid>
          <Row className="text-left my-3">{t("savingplanintro")}</Row>
          <Row>
            <AssetList onChange={this.handleCalculatorChange} />
            {/* <Calculator onCalculatorChange={this.handleCalculatorChange} /> */}
          </Row>

          <Row>
            {/* <label className="pl-0 pr-1 py-1" htmlFor="products">
              Products
            </label> */}
            <Form.Label>{t("Broker")}</Form.Label>
            <ToggleButtonGroup
              type="checkbox"
              className="ml-1 flex-wrap"
              value={this.state.selectedProducts}
              onChange={this.updateSelectedProducts}
            >
              {this.props.products.map((product) => (
                <ToggleButton key={product.id} value={product.id}>
                  {product.name}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Row>
        </Container>
        {/* 
        <div className="d-flex justify-content-start py-2">
          <label className="pl-0 pr-1 py-1" htmlFor="products">
            Products
          </label>
          <ToggleButtonGroup
            type="checkbox"
            defaultValue={[]}
            className="ml-1"
            value={this.state.selectedProducts}
            onChange={(val) => this.updateSelectedProducts(val)}
          >
            {this.props.products.map((product) => (
              <ToggleButton key={product.id} value={product.id}>
                {product.name}
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
        </div> */}
        {groupedComparisonTables}
      </div>
    );
  }
}

ComparisonView.propTypes = {
  products: PropTypes.array,
  t: PropTypes.func,
};

export default withTranslation("translations")(ComparisonView);
