import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { withTranslation } from "react-i18next";

class ComparisonTableRow extends React.Component {
  constructor(props) {
    super(props);
  }

  renderValue(product, value) {
    if (value == null) {
      return "N/A";
    }
    if (this.props.type == "iso8601") {
      return new Date(value).toLocaleDateString(undefined);
    } else if (this.props.type == "strarray") {
      return value.join(", ");
    } else if (this.props.type == "priceeur") {
      return value + " €";
    } else if (this.props.type == "percentage") {
      return value + " %";
    } else if (this.props.type == "priceperiodeur") {
      return (
        value +
        " € " +
        this.props.t("per") +
        " " +
        this.props.t(_.get(product, this.props.period, ""))
      );
    }
    return this.props.t(value);
  }

  render() {
    return (
      <tr>
        <th scope="row">{this.props.title}</th>
        {this.props.products.map((product) => (
          <td key={product.id + this.props.path} className="text-center">
            {this.renderValue(
              product,
              _.get(product, this.props.path, this.props.default)
            )}
          </td>
        ))}
      </tr>
    );
  }
}

ComparisonTableRow.propTypes = {
  title: PropTypes.string,
  products: PropTypes.array,
  path: PropTypes.string,
  default: PropTypes.string,
  type: PropTypes.string,
  period: PropTypes.string,
  t: PropTypes.func,
};

export default withTranslation("translations")(ComparisonTableRow);
