import React from "react";
import "./style.css";
import { Form } from "react-bootstrap";
import PropTypes from "prop-types";
import ComparisonTableRow from "../ComparisonTableRow";
import { withTranslation } from "react-i18next";
import _ from "lodash";

class ComparisonTable extends React.Component {
  constructor(props) {
    super(props);

    var selectedPlans = {};
    this.props.products.map((product) => (selectedPlans[product.id] = 0));
    this.state = {
      // store selected products in array
      selectedPlan: selectedPlans,
    };
    // console.log("ComparisonTable Constructor called");
    // console.log("this.state.selectedPlan", this.state.selectedPlan);
    this.getSelectedPlan = this.getSelectedPlan.bind(this);
    this.getProductPlanInfo = this.getProductPlanInfo.bind(this);
    this.updateProducts = this.updateProducts.bind(this);
    this.calculateCosts = this.calculateCosts.bind(this);
  }

  updateProducts(newSelectedProductIds) {
    // console.log("UpdateProducts");
    // console.log("newSelectedProductIds", newSelectedProductIds);
    this.setState((prevState) => {
      const selectedPlan = { ...prevState.selectedPlan };
      for (let productId of newSelectedProductIds) {
        const convertedProductId = parseInt(productId);
        if (convertedProductId in selectedPlan === false) {
          selectedPlan[convertedProductId] = 0;
        }
      }
      return { selectedPlan };
    });

    // console.log("this.state.selectedPlan", this.state.selectedPlan);
  }

  updateSelectedPlan(event, productId) {
    this.setState((prevState) => {
      const selectedPlan = { ...prevState.selectedPlan };
      selectedPlan[productId] = parseInt(event.target.value);
      return { selectedPlan };
    });

    // console.log(
    //   "updateSelectedPlan",
    //   "this.state.selectedPlan",
    //   this.state.selectedPlan
    // );
  }

  getSelectedPlan(product) {
    return this.state.selectedPlan[product.id];
  }

  getProductPlanInfo(product) {
    return product.plans[this.getSelectedPlan(product)];
  }

  calculateCosts(product) {
    // console.log("calculateCosts");
    const { assetList } = this.props.calculatorInput;
    // console.log(this.props.calculatorInput);
    // const selectedPlan = this.getSelectedPlan(product);
    // console.log(selectedPlan);

    const { etfCount, stockCount, etfList, stockList } = assetList;

    const planInfo = this.getProductPlanInfo(product);
    const depotFee = _.get(planInfo, "depot.fee", null);
    const etfFee = _.get(planInfo, "savingPlan.etf.fee", null);
    const etfPercentage = _.get(planInfo, "savingPlan.etf.percentage", null);
    const stockFee = _.get(planInfo, "savingPlan.stocks.fee", null);
    const stockPercentage = _.get(planInfo, "savingPlan.stocks.percentage", 0);
    const feeFreeCount = _.get(planInfo, "savingPlan.general.feefree", 0);

    let variableEtfCosts = 0;
    if (etfPercentage > 0) {
      for (const [assetCost, assetCount] of Object.entries(etfList)) {
        const interim = Math.max(
          etfFee,
          (etfPercentage / 100) * parseInt(assetCost)
        );
        // console.log(product.name, { interim });
        variableEtfCosts += interim * assetCount;
      }
    }
    let variableStockCosts = 0;
    if (stockPercentage > 0) {
      for (const [assetCost, assetCount] of Object.entries(stockList)) {
        const interim = Math.max(
          etfFee,
          (stockPercentage / 100) * parseInt(assetCost)
        );
        // console.log(product.name, { interim });
        variableStockCosts += interim * assetCount;
      }
    }
    // const depotFee = _.get(planInfo, "depot.fee", null);
    // const depotFee = _.get(planInfo, "depot.fee", null);
    let tmpEtfCount = etfCount;
    let tmpStockCount = stockCount;
    let tmpFeeFreeCount = feeFreeCount;
    while (tmpFeeFreeCount > 0 && (tmpEtfCount > 0 || tmpStockCount > 0)) {
      if ((tmpEtfCount > 0 && etfFee > stockFee) || tmpStockCount === 0) {
        tmpEtfCount -= 1;
        tmpFeeFreeCount -= 1;
      } else {
        tmpStockCount -= 1;
        tmpFeeFreeCount -= 1;
      }
    }
    const fixedEtfCosts = etfFee * tmpEtfCount;
    const fixedStockCosts = stockFee * tmpStockCount;

    // console.log(product.name, {
    //   etfCount,
    //   stockCount,
    //   fixedEtfCosts,
    //   fixedStockCosts,
    //   variableEtfCosts,
    //   variableStockCosts,
    // });
    const interval = 12;
    const result =
      variableEtfCosts * interval +
      fixedEtfCosts * interval +
      variableStockCosts * interval +
      fixedStockCosts * interval +
      depotFee * interval;
    return result.toFixed(2) + " €";
  }

  render() {
    const { t, products } = this.props;
    if (products && products.length <= 0) {
      return <div>Nothing to compare</div>;
    }

    const products_selected_plans = products.map((product) => {
      const planInfo = this.getProductPlanInfo(product);
      // console.log(this.state.selectedPlan);
      // console.log(planInfo);
      planInfo.id = product.id;
      return planInfo;
    });
    return (
      <div className="row compare">
        <div className="mt-3 text-center table-responsive-sm">
          <table className="table">
            <thead className="thead-default">
              <tr>
                <th />
                {products.map((product) => (
                  <th key={product.id}>
                    <a href={product.link}>{product.name}</a>
                    <Form.Control
                      as="select"
                      onChange={(event) =>
                        this.updateSelectedPlan(event, product.id)
                      }
                      disabled={product.plans.length <= 1 ? true : false}
                    >
                      {product.plans.map((plan, idx) => (
                        <option key={product.id + idx} value={idx}>
                          {plan.name}
                        </option>
                      ))}
                    </Form.Control>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody id="costs">
              <tr className="grouplabel">
                <th colSpan={products.length + 1}>{t("Costs")}</th>
              </tr>
              <tr>
                <th scope="row">{t("Costs (yearly)")}</th>
                {products.map((product) => (
                  <td key={"cost." + product.id} className="text-center">
                    {this.calculateCosts(product)}
                  </td>
                ))}
              </tr>
            </tbody>
            <tbody id="General">
              <tr className="grouplabel">
                <th colSpan={this.props.products.length + 1}>{t("General")}</th>
              </tr>
              <ComparisonTableRow
                title={t("Execution date")}
                path="savingPlan.general.executionDates"
                products={products_selected_plans}
                type="strarray"
              />
              <ComparisonTableRow
                title={t("Execution interval")}
                path="savingPlan.general.executionInterval"
                products={products_selected_plans}
                type="strarray"
              />
              <ComparisonTableRow
                title={t("Exchange")}
                path="savingPlan.general.exchange"
                products={products_selected_plans}
                type="strarray"
              />
              <ComparisonTableRow
                title={t("Depot fees")}
                path="depot.fee"
                products={products_selected_plans}
                type="priceperiodeur"
                period="depot.feePeriod"
              />
              <ComparisonTableRow
                title={t("Billing period")}
                path="depot.minBillingPeriod"
                products={products_selected_plans}
                type=""
              />
              <ComparisonTableRow
                title={t("Free execution")}
                path="savingPlan.general.feefree"
                products={products_selected_plans}
                type=""
                default="0"
              />
            </tbody>
            <tbody id="etf">
              <tr className="grouplabel">
                <th colSpan={this.props.products.length + 1}>ETF</th>
              </tr>
              <ComparisonTableRow
                title={t("Fixed fees")}
                path="savingPlan.etf.fee"
                products={products_selected_plans}
                type="priceeur"
              />
              <ComparisonTableRow
                title={t("Volume based costs")}
                path="savingPlan.etf.percentage"
                products={products_selected_plans}
                type="percentage"
              />
              <ComparisonTableRow
                title={t("Minimum savings rate")}
                path="savingPlan.etf.minSavingsRate"
                products={products_selected_plans}
                type="priceeur"
              />
              <ComparisonTableRow
                title={t("Maximum savings rate")}
                path="savingPlan.etf.maxSavingsRate"
                products={products_selected_plans}
                type="priceeur"
              />
              <ComparisonTableRow
                title={t("Number of ETFs")}
                path="savingPlan.etf.count"
                products={products_selected_plans}
                type=""
              />
            </tbody>
            <tbody id="stocks">
              <tr className="grouplabel">
                <th colSpan={this.props.products.length + 1}>{t("Stocks")}</th>
              </tr>
              <ComparisonTableRow
                title={t("Fixed fees")}
                path="savingPlan.stocks.fee"
                products={products_selected_plans}
                type="priceeur"
              />
              <ComparisonTableRow
                title={t("Volume based costs")}
                path="savingPlan.stocks.percentage"
                products={products_selected_plans}
                type="percentage"
              />
              <ComparisonTableRow
                title={t("Minimum savings rate")}
                path="savingPlan.stocks.minSavingsRate"
                products={products_selected_plans}
                type="priceeur"
              />
              <ComparisonTableRow
                title={t("Maximum savings rate")}
                path="savingPlan.stocks.maxSavingsRate"
                products={products_selected_plans}
                type="priceeur"
              />
              <ComparisonTableRow
                title={t("Number of Stocks")}
                path="savingPlan.stocks.count"
                products={products_selected_plans}
                type=""
              />
            </tbody>
            <tbody id="meta">
              <tr className="grouplabel">
                <th colSpan={this.props.products.length + 1}>Meta</th>
              </tr>
              <ComparisonTableRow
                title={t("Last update")}
                path="lastUpdate"
                products={products_selected_plans}
                type="iso8601"
              />
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

ComparisonTable.propTypes = {
  products: PropTypes.array,
  t: PropTypes.func,
  calculatorInput: PropTypes.object,
};

export default withTranslation("translations", { withRef: true })(
  ComparisonTable
);
